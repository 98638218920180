import { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import agent from "../../app/Agent/agent";

export default function Footer(props) {
    const [t, i18n] = useTranslation();
    const [formSent, setFormSent] = useState(false);

    const handleSubmit = useMemo(
        () => async (values) => {
            console.log(values);
            await agent.ContactUs.ContactUs(values);
            setFormSent(true);
        },
        []
    );

    return (
        <footer id="footer">
            <div className="max-container">
                <div className="f1">
                    <div className="holder">
                        <strong className="logo-footer">
                            <a href="/">
                                <span className="img">
                                    <img
                                        alt="Magic Photography"
                                        src={props.Footer_Logo}
                                    />
                                </span>
                            </a>
                        </strong>
                        {/* <!-- footer-txt --> */}
                        <div className="footer-txt">
                            <div className="text">
                                <div className="contact">
                                    <address>
                                        <span className="yellow">
                                            {t("Footer.Line-01.Sort")}
                                        </span>
                                        {t("Footer.Line-01.Content")}
                                    {/*     <br />
                                        {t("Footer.Line-01.NewLine")} */}
                                    </address>
                                    <span className="email">
                                        <span className="yellow">
                                            {t("Footer.Line-02.Sort")}
                                        </span>
                                        <a href="mailto:&#105;&#110;&#102;&#111;&#046;&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">
                                            {t("Footer.Line-02.Email")}
                                        </a>
                                    </span>
                                    <span className="tel">
                                        <span className="yellow">
                                            {t("Footer.Line-03.Sort")}
                                        </span>
                                        <a href="tel:00123456789">
                                            {t("Footer.Line-03.PhoneNumber")}
                                        </a>
                                    </span>
                                    <br/>
                                    <span>
                                        <ul className="soc-icon">
                                            <li>
                                                <a href="https://facebook.com" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72"><g transform="translate(-1409 -2274)"><g transform="translate(1409 2274)" fill="none" stroke="#c5a47e" stroke-width="1"><circle cx="36" cy="36" r="36" stroke="none"/><circle cx="36" cy="36" r="35.5" fill="none"/></g><path d="M43.957,23.677l1.169-7.618H37.817V11.116C37.817,9.032,38.838,7,42.111,7h3.323V.515A40.525,40.525,0,0,0,39.536,0c-6.02,0-9.954,3.649-9.954,10.254V16.06H22.89v7.618h6.691V42.093h8.235V23.677Z" transform="translate(1411.109 2289)" fill="#c5a47e"/></g></svg>
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href="https://instagram.com" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72"><g transform="translate(-1597 -2274)"><g transform="translate(1597 2274)" fill="none" stroke="#c5a47e" stroke-width="1"><circle cx="36" cy="36" r="36" stroke="none"/><circle cx="36" cy="36" r="35.5" fill="none"/></g><path d="M20.967,42.323a11.052,11.052,0,0,0,0,22.1,11.052,11.052,0,0,0,0-22.1Zm0,18.232a7.185,7.185,0,1,1,7.012-7.183,7.112,7.112,0,0,1-7.012,7.183ZM34.708,41.871a2.516,2.516,0,1,1-2.516-2.577A2.541,2.541,0,0,1,34.708,41.871Zm7.143,2.616c-.16-3.452-.929-6.51-3.4-9.029S33.01,32.15,29.64,31.976c-3.473-.2-13.882-.2-17.355,0-3.36.163-6.345.952-8.814,3.471S.242,41.025.073,44.477c-.2,3.558-.2,14.222,0,17.78.16,3.452.929,6.51,3.4,9.029s5.444,3.308,8.814,3.481c3.473.2,13.882.2,17.355,0,3.37-.163,6.355-.952,8.814-3.481s3.229-5.577,3.4-9.029C42.049,58.7,42.049,48.044,41.851,44.487ZM37.365,66.074a7.188,7.188,0,0,1-4,4.1c-2.769,1.125-9.339.865-12.4.865s-9.64.25-12.4-.865a7.188,7.188,0,0,1-4-4.1c-1.1-2.837-.845-9.568-.845-12.7s-.244-9.875.845-12.7a7.188,7.188,0,0,1,4-4.1c2.769-1.125,9.339-.865,12.4-.865s9.64-.25,12.4.865a7.188,7.188,0,0,1,4,4.1c1.1,2.837.845,9.568.845,12.7S38.463,63.247,37.365,66.074Z" transform="translate(1612.075 2256.175)" fill="#c5a47e"/></g></svg>
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href="https://wa.me/971506225702" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72"><g transform="translate(-60 -301)"><g transform="translate(-1349 -1973)"><g transform="translate(1409 2274)" fill="none" stroke="#c5a47e" stroke-width="1"><circle cx="36" cy="36" r="36" stroke="none"/><circle cx="36" cy="36" r="35.5" fill="none"/></g></g><path d="M33.5,37.726A19.532,19.532,0,0,0,2.771,61.288L0,71.4l10.352-2.718a19.466,19.466,0,0,0,9.332,2.375h.009A19.4,19.4,0,0,0,33.5,37.726ZM19.693,67.77a16.2,16.2,0,0,1-8.268-2.26l-.589-.352L4.7,66.768l1.636-5.99-.387-.616A16.26,16.26,0,1,1,36.1,51.534,16.41,16.41,0,0,1,19.693,67.77Zm8.9-12.155c-.484-.246-2.885-1.425-3.333-1.583s-.774-.246-1.1.246S22.9,55.862,22.613,56.2s-.572.369-1.055.123c-2.867-1.434-4.749-2.559-6.64-5.8-.5-.862.5-.8,1.434-2.665A.9.9,0,0,0,16.306,47c-.123-.246-1.1-2.647-1.5-3.624-.4-.95-.8-.818-1.1-.836-.281-.018-.607-.018-.932-.018a1.807,1.807,0,0,0-1.3.607A5.482,5.482,0,0,0,9.763,47.2a9.558,9.558,0,0,0,1.988,5.049c.246.325,3.439,5.251,8.338,7.37,3.1,1.337,4.31,1.451,5.858,1.223.941-.141,2.885-1.179,3.289-2.322a4.08,4.08,0,0,0,.281-2.322C29.4,55.976,29.077,55.853,28.594,55.615Z" transform="translate(76.299 285.299)" fill="#c5a47e"/></g></svg>
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href="">

                                                </a>
                                            </li>
                                            
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div id="form" className="frame">
                        <div className="wpcf7">
                            <Formik
                                onSubmit={handleSubmit}
                                initialValues={{
                                    fullName: "",
                                    email: "",
                                    message: "",
                                }}
                                validationSchema={Yup.object({
                                    fullName: Yup.string().required(),
                                    email: Yup.string().email().required(),
                                    message: Yup.string().required().max(300),
                                })}
                            >
                                {({
                                    handleSubmit,
                                    isSubmitting,
                                    errors,
                                    dirty,
                                    isValid,
                                    values,
                                    handleBlur,
                                    handleChange,
                                    touched,
                                }) => (
                                    <Form
                                        onSubmit={(e) => {
                                            handleSubmit(e);
                                            e.preventDefault();
                                        }}
                                        className="wpcf7-form f-contact-form"
                                    >
                                        <fieldset
                                            disabled={isSubmitting || formSent}
                                        >
                                            <h3>{t("Footer.titleForm")}</h3>
                                            <div className="rows">
                                                <span className="wpcf7-form-control-wrap text-field-required">
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        style={
                                                            errors.fullName &&
                                                            touched.fullName
                                                                ? {
                                                                      borderColor:
                                                                          "red",
                                                                  }
                                                                : undefined
                                                        }
                                                        value={values.fullName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required text-field-className wpcf7-use-title-as-watermark"
                                                        size="12"
                                                        placeholder={t(
                                                            "Footer.placeholderName"
                                                        )}
                                                    />
                                                </span>
                                            </div>
                                            <div className="rows">
                                                <span className="wpcf7-form-control-wrap Emailfield">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={values.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email email-className wpcf7-use-title-as-watermark"
                                                        size="12"
                                                        style={
                                                            errors.email &&
                                                            touched.email
                                                                ? {
                                                                      borderColor:
                                                                          "red",
                                                                  }
                                                                : undefined
                                                        }
                                                        placeholder={t(
                                                            "Footer.placeholderEmail"
                                                        )}
                                                    />
                                                </span>
                                            </div>
                                            <div className="rows">
                                                <span className="wpcf7-form-control-wrap textarea">
                                                    <textarea
                                                        name="message"
                                                        value={values.message}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="wpcf7-form-control wpcf7-textarea textarea-className wpcf7-use-title-as-watermark"
                                                        rows="9"
                                                        style={
                                                            errors.message &&
                                                            touched.message
                                                                ? {
                                                                      borderColor:
                                                                          "red",
                                                                  }
                                                                : undefined
                                                        }
                                                        cols="39"
                                                        placeholder={t(
                                                            "Footer.placeholderTextarea"
                                                        )}
                                                    ></textarea>
                                                </span>
                                            </div>
                                        </fieldset>

                                        <button
                                            id="submit"
                                            type="submit"
                                            children={
                                                formSent
                                                    ? t(
                                                          "Footer.DoneMessage.Thanks"
                                                      )
                                                    : t(
                                                          "Footer.ButtonFormValue"
                                                      )
                                            }
                                            disabled={
                                                (!dirty && !isValid) ||
                                                isSubmitting ||
                                                formSent
                                            }
                                        />
                                    </Form>
                                )}
                            </Formik>
                            {formSent && (
                                <div className="done-massage">
                                    <strong>
                                        {t("Footer.DoneMessage.Thanks")}
                                    </strong>
                                    {t("Footer.DoneMessage.Message")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="f2">
                    {/* <!-- sub-nav --> */}
                    <ul className="sub-nav">
                        <li>
                            <a href="#wrapper">{t("header.link-01")}</a>
                        </li>
                        <li>
                            <a href="#section1">{t("header.link-02")}</a>
                        </li>
                        <li>
                            <a href="#section2">{t("header.link-03")}</a>
                        </li>
                        <li>
                            <a href="#section3">{t("header.link-04")}</a>
                        </li>
                        {/*     <li>
                            <a href="#section5">{t("header.link-05")}</a>
                        </li> */}
                    </ul>
                    <span className="copyright">
                        {t("Footer.CopyRight.Copy")}
                        <a href="/">{t("Footer.CopyRight.Link")}</a> &copy;
                        <span id="dt">{new Date().getFullYear()}</span>
                        {t("Footer.CopyRight.AllRights")}
                    </span>
                </div>
            </div>
        </footer>
    );
}
